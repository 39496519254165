import Parse, { Config } from 'parse';
import Noti from './Noti';
import Payment from './Payment';
import _ from 'underscore';
import { log } from './Log'
import config from '../config';
import axios from 'axios';

var User = class User {
	constructor() {
		this.qualificationTypes = {
			NONE: 0,
			PRACTITIONER: 1,
			INSTRUCTOR: 2,
			TEACHER: 3,
			STUDIO: 4
		}

		this.studioTypes = {
			TEACHER: 3,
			STUDIO: 4
		}

		this.sortTableTypes = {
			STUDENT_SORT: "student_sort",
			SESSION_SORT: "session_sort",
			PLAN_SORT: "plan_sort"
		}

		this.filterTableTypes = {
			STUDENT_FILTER: "student_filter",
			SESSION_FILTER: "session_filter",
			PLAN_FILTER: "plan_filter"
		}

		this.filterOptionToIndex = {
			PAID: 0,
			NOT_PAID: 1,
			HAVE_MEMBERSHIP: 2,
			NO_MEMBERSHIP: 3,
			HAVE_TICKET: 4,
			NO_TICKET: 5,
			HAVE_PLAN: 6,
			NO_PLAN: 7,
			ACTIVE: 8,
			NOT_ACTIVE: 9,
			NEEDS_RECEIPT: 10,
			HAVE_RECEIPT: 11
		}

		this.sortOptionToIndex = {
			CREATED: 0,
			UPDATED: 1,
			NAME: 2,
			TITLE: 3,
			EMAIL: 4,
			PHONE: 5,
			DATE: 6,
			EXPIRATION_DATE: 7,
			PAID: 8,
			HIDDEN: 9,
			PRICE: 10,
			FREQUENT: 11,
			FROZEN: 12
		}

		this.sortOptionToText = {
			CREATED: showTextByBusinessType('sortOptions.CREATED'),
			UPDATED: showTextByBusinessType('sortOptions.UPDATED'),
			NAME: showTextByBusinessType('sortOptions.NAME'),
			TITLE: showTextByBusinessType('sortOptions.TITLE'),
			EMAIL: showTextByBusinessType('sortOptions.EMAIL'),
			PHONE: showTextByBusinessType('sortOptions.PHONE'),
			DATE: showTextByBusinessType('sortOptions.DATE'),
			EXPIRATION_DATE: showTextByBusinessType('sortOptions.EXPIRATION_DATE'),
			PAID: showTextByBusinessType('sortOptions.PAID'),
			HIDDEN: showTextByBusinessType('sortOptions.HIDDEN'),
			PRICE: showTextByBusinessType('sortOptions.PRICE'),
			FREQUENT: showTextByBusinessType('sortOptions.FREQUENT'),
			FROZEN: showTextByBusinessType('sortOptions.FROZEN')
		}

		this.filterOptionToText = {
			PAID: showTextByBusinessType('filterOptions.PAID'),
			NOT_PAID: showTextByBusinessType('filterOptions.NOT_PAID'),
			HAVE_MEMBERSHIP: showTextByBusinessType('filterOptions.HAVE_MEMBERSHIP'),
			NO_MEMBERSHIP: showTextByBusinessType('filterOptions.NO_MEMBERSHIP'),
			HAVE_TICKET: showTextByBusinessType('filterOptions.HAVE_TICKET'),
			NO_TICKET: showTextByBusinessType('filterOptions.NO_TICKET'),
			HAVE_PLAN: showTextByBusinessType('filterOptions.HAVE_PLAN'),
			NO_PLAN: showTextByBusinessType('filterOptions.NO_PLAN'),
			ACTIVE: showTextByBusinessType('filterOptions.ACTIVE'),
			NOT_ACTIVE: showTextByBusinessType('filterOptions.NOT_ACTIVE'),
			NEEDS_RECEIPT: showTextByBusinessType('filterOptions.NEEDS_RECEIPT'),
			HAVE_RECEIPT: showTextByBusinessType('filterOptions.HAVE_RECEIPT')
		}
	}
	getAttributes() {
		if (!Parse.User || !Parse.User.current()) {
			return null;
		}
		return Parse.User.current().attributes
	}

	getAttr(key) {
		if (!this.getAttributes()) {
			return null;
		}

		return Parse.User.current().attributes[key];
	}

	getUserImageUrl() {
		if (!this.getAttributes()) {
			return undefined;
		}

		return this.getAttributes().user_image_url || undefined;
	}

	getEmail() {
		if (!this.getAttributes()) {
			return null;
		}

		return this.getAttributes().email || null;
	}

	getFirstName() {
		if (!this.getAttributes()) {
			return null;
		}

		return this.getAttributes().first_name || null;
	}

	getLastName() {
		if (!this.getAttributes()) {
			return null;
		}

		return this.getAttributes().last_name || null;
	}

	hasUserPassedWizard() {
		return !!this.getAttributes().subscription;
	}

	getInterestsByMainCategory(mainCat) {
		let interests = this.getAttr('interests') || [];
		return _.filter(interests, (interest) => {
			return interest.get('main_category') === mainCat;
		});
	}

	getInterestsIdsByMainCategory(mainCat) {
		this.getInterestsByMainCategory(mainCat).map((interest) => {
			return interest.id;
		})
	}

	findOtherUsersByEmail(params, callback) {
		var text = params.text;
		// if(params.filterNonFollowers){
		this.tryExactEmailUsersTextSearch(text, (err, res) => {
			log("tryExactEmailUsersTextSearch", res);
			callback(null, res);
		});
	}

	findUserById(id, callback) {
		var anotherUser = Parse.Object.extend('User');
		var query = new Parse.Query(anotherUser);

		query.equalTo('objectId', id)

		query.find({
			success: (results) => {
				var res = results.length ? results[0] : undefined;
				callback(null, results[0])
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	fetchUser(callback) {
		try {
			log('fetchUser');
			if (Parse.User.current()) {
				Parse.User.current().fetch()
					.then((user) => {
						if (Parse.User.current().get('preferences')) {
							Parse.User.current().get('preferences').fetch()
								.then((userPreferences) => {
									if (Parse.User.current().get("subscription")) {
										Parse.User.current().get("subscription").fetch()
											.then((subscription) => {
												subscription.get("membership").fetch()
													.then((membership) => {
														callback();
													}).catch((error) => {
														log('error getting membership of user', error);
														callback();
													});
											}).catch((error) => {
												log('error getting subscription of user', error);
												callback();
											});
									} else {
										callback();
									}
								}).catch((error) => {
									log('error getting prefs of user', error);
									callback();
								});
						}
					}).catch((error) => {
						log('error fetching user', error);
						callback();
					});

			}
		} catch (error) {
			log('error getting prefs of user', error);
			callback();
		}
	}

	hasUserRegisteredToBilling() {
		try {
			log('hasUserRegisteredToBilling?', (Parse.User.current().get('payme_seller_id') || Parse.User.current().get('grow_seller_id')));
			if ((Parse.User.current().get('payme_seller_id') || Parse.User.current().get('grow_seller_id'))) {
				return true;
			} else {
				return false;
			}

		} catch (error) {
			return false;
		}
	}

	isRegisteredToGrow() {
		try {
			log('isRegisteredToGrow?', !!Parse.User.current().get('grow_seller_id'));
			if (Parse.User.current().get('grow_seller_id')) {
				return true;
			} else {
				return false;
			}

		} catch (error) {
			return false;
		}
	}

	isRegisteredToPayMe() {
		try {
			log('isRegisteredToPayMe?', !!Parse.User.current().get('payme_seller_id'));
			if (Parse.User.current().get('payme_seller_id')) {
				return true;
			} else {
				return false;
			}

		} catch (error) {
			return false;
		}
	}

	updateUser(props, callback) {
		var currentUser = Parse.User.current();
		if (props.email) {
			props.email = props.email.toLowerCase()
		}
		currentUser.set(props);
		currentUser.save(null, {
			success: function (user) {
				try {
					currentUser.fetch();
					log('success updateUser')
					if (callback)
						callback(null, user);
				} catch (e) {
					log(e);
				}
			},
			error: function (user, error) {
				log('Failed to update object, with error code: ' + error.message);
				if (callback)
					callback(error, null);
			}
		});
	}

	getUserPaymentProcessorName() {
		try {
			var user = Parse.User.current();
			if (user.get('bank_country') === 'Israel') {
				return 'payme';
			} else if (user.get('bank_country') === 'United States') {
				return 'splash'
			} else {
				return 'payme';
			}
		} catch (error) {
			return 'payme';
		}
		// return 'none';
	}

	isPhoneNumberValid() {
		try {
			var user = Parse.User.current();
			let phoneNumber = user.get('phone_number').replace('+', "");
			if (this.validatePhoneNumber(phoneNumber)) {
				return true;
			}
			let isIsraeliPhoneNumber = user.get('bank_country') === 'Israel' || phoneNumber.startsWith('972');
			let isUSAPhoneNumber = user.get('bank_country') === 'United States' || phoneNumber.startsWith('1');

			if (phoneNumber && phoneNumber.length > 10 && (isIsraeliPhoneNumber || isUSAPhoneNumber)) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	validatePhoneNumber(phoneNumber) {
		const phoneRegex = /^05\d{8}$/;
		return phoneRegex.test(phoneNumber);
	}

	isBankCountryValid() {
		try {
			var user = Parse.User.current();
			if (user.get('bank_country') === 'Israel') {
				return true;
			} else if (user.get('bank_country') === 'United States') {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	isBankCountryIsrael() {
		try {
			var user = Parse.User.current();
			if (user.get('bank_country') === 'Israel') {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	getNewSellerRegistrationLink() {
		try {
			var user = Parse.User.current();
			if (user.get('bank_country') === 'Israel') {
				return config.PAYME_REGISTER_DASHBOARD_URL;
			} else if (user.get('bank_country') === 'United States') {
				return config.PAYME_USA_REGISTER_DASHBOARD_URL;
			} else {
				return undefined;
			}
		} catch (error) {
			return undefined;
		}
	}

	getNewSellerRegistrationLinkGrow(params, callback) {
		try {
			Parse.Cloud.run('getGrowSellerRegistrationLink', { companyId: params.field0, phoneNumber: params.field1 }, {
				success: function (link) {
					callback(link, undefined);
				},
				error: function (error) {
					log('error', error);
					callback(undefined, error);
				}
			});
		} catch (error) {
			return undefined;
		}
	}

	getPreferences() {
		if (!this.getAttr('preferences')) {
			return {};
		}
		return this.getAttr('preferences').attributes || {};
	}

	getTimeFormatValue() {
		if (this.getPreferences().time_format || this.getPreferences().time_format === 0) {
			return this.getPreferences().time_format;
		} else {
			var language = this.getPreferences().language || "en";
			return language == 'en' ? 1 : 0;
		}
	}
	getTimeFormat() {
		if (this.getPreferences().time_format || this.getPreferences().time_format === 0) {
			return this.getPreferences().time_format == 0 ? 'HH:mm' : 'h:mma';
		} else {
			var language = this.getPreferences().language || "en";
			return language == 'en' ? 'h:mma' : 'HH:mm';
		}
	}

	getTimeFormatExternal(timeFormat) {
		if (timeFormat || timeFormat === 0) {
			return timeFormat == 0 ? 'HH:mm' : 'h:mma';
		} else {
			return 'h:mma';
		}
	}

	saveUserPreferences(props, callback) {
		try {
			log('saveUserPreferences', props)
			var UserPreferences;
			var userPreferences;
			if (Parse.User.current().get('preferences')) {
				userPreferences = Parse.User.current().get('preferences');
			} else {
				UserPreferences = Parse.Object.extend('UserPreferences');
				userPreferences = new UserPreferences();
			}

			userPreferences.save(props, {
				success: (userPreferences) => {
					log('success save user preferences')
					this.updateUser({ preferences: userPreferences }, (err, res) => {
						if (err) {
							log('failed save user preferences', err)
							if (callback)
								callback(err, null);
						} else {
							log('success save user preferences and update user', res.id)
							if (callback)
								callback(null, userPreferences);
						}
					})
				},
				error: function (error) {
					log('failed save user preferences', error)
					if (callback)
						callback(error, null)
				}
			});
		} catch (e) {
			log(e)
			if (callback)
				callback(e, null)
		}
	}

	checkRequestedUsers(callback) {
		log('checkRequestedUsers');
		Parse.Cloud.run('checkRequestedUsers', {
			teacherId: Parse.User.current().id
		}, {
			success: function (student) {
				log(student)
				callback(null, student);
			},
			error: function (error) {
				log('error', error);
				callback(error, undefined);
			}
		});
	}

	addStudentToRequestedUserRelation(props, callback) {
		log('addStudentToRequestedUserRelation', props);
		Parse.Cloud.run('addStudentToRequestedUserRelation', {
			teacherId: Parse.User.current().id,
			studentId: props.student.id
		}, {
			success: function (result) {
				log(result)
				callback(null, true);
			},
			error: function (error) {
				log('error', error);
				callback(error, false);
			}
		});
	}

	removeStudentFromRequestedUserRelation(props, callback) {
		log('removeStudentFromRequestedUserRelation', props);
		Parse.Cloud.run('removeStudentFromRequestedUserRelation', {
			teacherId: Parse.User.current().id,
			studentId: props.student.id
		}, {
			success: function (result) {
				log(result)
				callback(null, true);
			},
			error: function (error) {
				log('error', error);
				callback(error, false);
			}
		});
	}

	removeStudentFromRequestedUserRelationAndApprove(props, callback) {
		try {
			log('removeStudentFromRequestedUserRelationAndApprove', props);
			Parse.Cloud.run('removeStudentFromRequestedUserRelationAndApprove', {
				teacherId: props.teacherId,
				studentId: props.studentId
			}, {
				success: function (result) {
					log(result)
					callback(null, true);
				},
				error: function (error) {
					log('error', error);
					callback(error, false);
				}
			});
		} catch (e) {
			log(e);
		}
	}

	removeStudentsFromRequestedUserRelationAndApprove(props, callback) {
		try {
			log('removeStudentsFromRequestedUserRelationAndApprove', props);
			Parse.Cloud.run('removeStudentsFromRequestedUserRelationAndApprove', {
				teacherId: props.teacherId,
				students: props.students
			}, {
				success: function (result) {
					log(result)
					callback(null, true);
				},
				error: function (error) {
					log('error', error);
					callback(error, false);
				}
			});
		} catch (e) {
			log(e);
		}
	}

	createNewUser(props, callback) {

		var AddExistingUserToStudio = (props, notify) => {
			Parse.Cloud.run('addRequestingUserToExistingUsersACL', { email: props.email, teacherId: props.teacherId }, {
				success: (existingUser) => {
					log('Existing User ' + existingUser.id);
					if (notify) {
						props.existingUser = true;

						var sendEmailInvites = this.getPreferences().send_email_invites;
						log('sendEmailInvites', sendEmailInvites);

						if (!props.isAffiliates && (sendEmailInvites == undefined ? true : sendEmailInvites)) {
							Noti.sendCompletelyNewUserAddedForSessionOrTeacher(props, existingUser);
						}
					}
					callback(null, existingUser);
				},
				error: function (error) {
					log('error', error);
					if (notify) {
						callback(error, null);
					}
				}
			});
		}

		if (!props.email) {
			if (Parse.User.current()) {
				props.email = Parse.User.current().get('email');
				AddExistingUserToStudio(props, !props.archived);
				return;
			} else {
				return;
			}

		}

		log('USER TYPE!', props.userType); // string: 'student' / 'teacher'

		Parse.Cloud.run('createNewUser', {
			firstName: props.firstName || '',
			lastName: props.lastName || '',
			email: props.email,
			phoneNumber: props.phoneNumber,
			userType: props.userType,
			password: props.password,
			teacherId: props.teacherId,
			promotion_consent: props.promotion_consent
		})
			.then((newUser) => {
				log('success createNewUser ' + newUser.id)
				var sendEmailInvites = this.getPreferences().send_email_invites;

				if (!props.archived && !props.isAffiliates) {
					if (props.userType === 'student' && (sendEmailInvites == undefined ? true : sendEmailInvites)) {
						//emailType 16 when creating a new user to add to attenders list
						Noti.sendCompletelyNewUserAddedForSessionOrTeacher(props, newUser);
					} else if (props.userType === 'teacher') {
						//emailType 17 when creating a new user to add to studio teachers list
						Noti.sendCompletelyNewUserAddedForStudioAsTeacher(props, newUser);
					}
					AddExistingUserToStudio(props, false);
				} else if (props.archived && (!Parse.User.current() || newUser.id == Parse.User.current().id)) {
					//Notify in case of archived but performed by student itself 
					//(we can also get here after a studio added a student into archive)
					Noti.sendNewUserAddedForStudioAsLead(props, newUser);
					AddExistingUserToStudio(props, false);
				}
			})
			.catch((error) => {
				log('error createNewUser ', error);
				// 202 This means the user already exists and we want to add access to the requesting user
				if ((error.code && error.code === 202) || error.message.code === 202 ||
					(error.message.message && error.message.message.code === 202)) {
					AddExistingUserToStudio(props, !props.archived);
				} else {
					log('error', error);
					callback(error, null);
				}
			});
	}

	getStudentNote(opts, callback) {
		log('getStudentNote', opts)
		let { session, student, teacher } = opts;
		if (!student) {
			return;
		}
		teacher = teacher || Parse.User.current();

		log('teacher ID', teacher.id);
		var Note = Parse.Object.extend('Note');
		var query = new Parse.Query(Note);

		query.equalTo('student', student);
		query.equalTo('teacher', teacher);
		if (session) {
			log('Note for session');
			query.equalTo('session', session)
		} else {
			log('Entering here for the right  reason');
			query.doesNotExist('session');
		}
		query.include('student');
		query.find({
			success: (results) => {
				log('getStudentNote', results);
				results = this.removeNoteWithPrivateACLStudent(results);
				log('getStudentNote after removeNoteWithPrivateACLStudent', results);
				callback(null, results[0])
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getStudentsNotes(opts, callback) {
		log('getStudentNotes', opts)
		try {
			let { session, students, teacher } = opts;
			if (!students) {
				return;
			}

			teacher = teacher || Parse.User.current();

			//Get from server because note with private acl
			if (teacher.id != Parse.User.current().id) {
				Parse.Cloud.run('getSessionNotes',
					{
						teacherId: Parse.User.current().id,
						sessionId: session ? session.id : undefined
					}, {
					success: (results) => {
						log('getSessionNotes results', results);
						callback(null, results)
					},
					error: function (error) {
						log('getSessionNotes error', error);
						callback(error, undefined)
					}
				});
			} else {
				var Note = Parse.Object.extend('Note');
				var query = new Parse.Query(Note);

				var studentNotesQuery = new Parse.Query(Note);
				studentNotesQuery.containedIn('student', students)
				studentNotesQuery.include('student');
				studentNotesQuery.equalTo('teacher', teacher);
				studentNotesQuery.doesNotExist('session');

				if (session) {
					log('we have session', session)
					log('we have teacher', teacher)
					var sessionNotesQuery = new Parse.Query(Note);
					sessionNotesQuery.equalTo('session', session);
					sessionNotesQuery.equalTo('teacher', teacher);
					sessionNotesQuery.containedIn('student', students)
					sessionNotesQuery.include('student');
					var orQueries = [studentNotesQuery, sessionNotesQuery];
					query = new Parse.Query.or(...orQueries);
				} else {
					query = studentNotesQuery;
				}

				query.limit((2 * students.length));
				query.find({
					success: (results) => {
						log('Total Notes', results.length)
						results = this.removeNoteWithPrivateACLStudent(results);
						log('Total Notes after removeNoteWithPrivateACLStudent', results.length);
						callback(null, results)
					},
					error: function (error) {
						log('No Notes..')
						callback(error, null)
					}
				});
			}
		} catch (e) {
			log(e)
		}
	}

	getStudioStudentsCount(archivedStudents, callback) {
		log('getStudioStudentsCount', archivedStudents);
		return new Promise((resolve, reject) => {
			try {
				Parse.Cloud.run('getStudioStudents', {
					teacherId: Parse.User.current().id,
					onlyCount: true,
					archivedStudents: archivedStudents
				}, {
					success: function (count) {
						log('number of students', count);
						if (callback) {
							callback(null, count);
						}
						resolve(count);
					},
					error: function (error) {
						log('Error getting studio students count:', error);
						if (callback) {
							callback(error, 0);
						}
						reject(error);
					}
				});
			} catch (e) {
				log(e);
				if (callback) {
					callback(e, 0);
				}
				reject(e);
			}
		});
	}

	// getStudioStudentsCount(archivedStudents, callback) {
	// 	log('getStudioStudentsCount', archivedStudents);
	// 	return new Promise((resolve, reject) => {
	// 		try {
	// 			let teacher = Parse.User.current();

	// 			var Note = Parse.Object.extend('Note');
	// 			var studentNotesQuery = new Parse.Query(Note);
	// 			studentNotesQuery.equalTo('teacher', teacher);
	// 			studentNotesQuery.doesNotExist('session');

	// 			//Filter students that are not followers
	// 			var Follow = Parse.Object.extend('Follow');
	// 			var followersQuery = new Parse.Query(Follow);
	// 			followersQuery.equalTo('to', teacher);

	// 			if (archivedStudents) {
	// 				studentNotesQuery.doesNotMatchKeyInQuery('student', 'from', followersQuery);
	// 			} else {
	// 				studentNotesQuery.matchesKeyInQuery('student', 'from', followersQuery);
	// 			}
	// 			// studentNotesQuery.matchesKeyInQuery('student', 'from', followersQuery);

	// 			studentNotesQuery.limit(5000);
	// 			studentNotesQuery.count().then((count) => {
	// 				log('number of students', count);
	// 				if (callback) {
	// 					callback(null, count);
	// 				}
	// 				resolve(count);
	// 			}).catch((error) => {
	// 				log('No Notes..');
	// 				if (callback) {
	// 					callback(error, 0);
	// 				}
	// 				reject(error);
	// 			});
	// 		} catch (e) {
	// 			log(e);
	// 			if (callback) {
	// 				callback(e, 0);
	// 			}
	// 			reject(e);
	// 		}
	// 	});
	// }

	getStudioStudentsFromNotes(archivedStudents, limit, callback) {
		log('getStudioStudentsFromNotes');
		log('archivedStudents', archivedStudents);
		log('limit', limit);
		try {

			let teacher = Parse.User.current();

			var Note = Parse.Object.extend('Note');

			var studentNotesQuery = new Parse.Query(Note);


			studentNotesQuery.equalTo('teacher', teacher);
			studentNotesQuery.doesNotExist('session');

			//Filter students that are not followers
			var Follow = Parse.Object.extend('Follow');
			var followersQuery = new Parse.Query(Follow);
			followersQuery.equalTo('to', teacher);

			if (archivedStudents) {
				studentNotesQuery.doesNotMatchKeyInQuery('student', 'from', followersQuery);
			} else {
				studentNotesQuery.matchesKeyInQuery('student', 'from', followersQuery);
			}

			studentNotesQuery.descending('updatedAt');
			studentNotesQuery.include('student');
			studentNotesQuery.include('student.email');
			studentNotesQuery.include('student.username');
			studentNotesQuery.limit(limit ? limit : 3000);
			studentNotesQuery.find({
				success: (results) => {
					log('Total Student Notes', results)
					results = this.removeNoteWithPrivateACLStudent(results);
					log('Total Student Notes after removeNoteWithPrivateACLStudent', results.length);
					callback(null, results)
				},
				error: function (error) {
					log('No Notes..')
					callback(error, null)
				}
			});
		} catch (e) {
			log(e)
		}
	}

	getStudentNoteForSession(opts, callback) {
		log('getStudentNoteForSession', opts)
		let { session, student, teacher } = opts;
		if (!student) {
			return;
		}
		teacher = teacher || Parse.User.current();

		log('teacher ID', teacher.id);
		var Note = Parse.Object.extend('Note');
		var query = new Parse.Query(Note);

		query.equalTo('student', student);
		query.equalTo('teacher', teacher);
		query.equalTo('session', session)
		query.include('student');
		query.include('session');
		query.find({
			success: (results) => {
				results = this.removeNoteWithPrivateACLStudent(results);
				log('Total Notes after removeNoteWithPrivateACLStudent', results.length);
				callback(null, results.length > 0 ? results[0] : null)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getStudentNotesForSessions(opts, callback) {
		log('getStudentNotesForSessions', opts)
		let { sessions, student, teacher } = opts;
		if (!sessions) {
			return;
		}
		teacher = teacher || Parse.User.current();

		var Note = Parse.Object.extend('Note');
		var query = new Parse.Query(Note);

		query.containedIn('session', sessions);
		query.include('session');
		query.equalTo('teacher', teacher);
		if (student) {
			query.equalTo('student', student);
		} else {
			// query.doesNotExist('session');
		}
		query.find({
			success: (results) => {
				log('Total Notes', results.length)
				results = this.removeNoteWithPrivateACLStudent(results);
				log('Total Notes after removeNoteWithPrivateACLStudent', results.length);
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	createStudentNote(opts, callback) {
		log('createStudentNote', opts)
		let { session, student, teacher, noteText } = opts;
		if (!student) {
			return;
		}
		teacher = teacher || Parse.User.current();

		var Note = Parse.Object.extend('Note');
		var noteInstance = new Note();

		//Only the teacher creating the note has access to this note
		var acl = new Parse.ACL();
		acl.setReadAccess(teacher.id, true);
		acl.setWriteAccess(teacher.id, true);
		acl.setPublicReadAccess(false);
		var params = session ? {
			session, student, teacher, note: noteText, ACL: acl
		} : { student, teacher, note: noteText, ACL: acl };
		log('params ', params);
		noteInstance.save(params, {
			success: function (res) {
				log('note create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	createStudentNoteWithStatus(opts, callback) {
		log('createStudentNoteWithStatus', opts)
		let { session, student, teacher, status } = opts;
		if (!student) {
			return;
		}
		teacher = teacher || Parse.User.current();

		var Note = Parse.Object.extend('Note');
		var noteInstance = new Note();
		;
		log('createStudentNoteWithStatus status', status);
		//Only the teacher creating the note has access to this note
		var acl = new Parse.ACL();
		acl.setReadAccess(teacher.id, true);
		acl.setWriteAccess(teacher.id, true);
		if (status && teacher.id != Parse.User.current().id) {
			acl.setReadAccess(Parse.User.current().id, true);
			acl.setWriteAccess(Parse.User.current().id, true);
		}
		acl.setPublicReadAccess(false);
		var params = session ? {
			session, student, teacher, ACL: acl, status: status
		} : { student, teacher, status: status, ACL: acl };
		log('params ', params);
		noteInstance.save(params, {
			success: function (res) {
				log('note create success', res)
				callback(undefined, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	setStudentNote(opts, callback) {
		log('setStudentNote', opts);
		let { student, session, teacher, noteText, notes } = opts;
		if (!student) {
			return;
		}
		teacher = teacher || Parse.User.current();
		this.getStudentNote(opts, (err, noteRes) => {

			if (err) {
				log(err, noteRes)
				return;
			}

			if (!noteRes) {
				log('Note wasn\'t found for user ' + student.id + ' and teacher id ' + teacher.id);
				this.createStudentNote(opts, callback);
			} else {
				var params = session ? {
					session, student, teacher, note: noteText, notes: notes
				} : { student, teacher, note: noteText, notes: notes };
				noteRes.save(params, {
					success: function (res) {
						log('save note!')
						callback(null, res)
					},
					error: function (err) {
						callback(err);
					}
				})
			}
		})
	}

	getTeacherStudentForm(opts, callback) {
		log('getTeacherStudentForm', opts)
		let { student, teacher, type, exists } = opts;
		teacher = teacher || Parse.User.current();

		Parse.Cloud.run('getTeacherStudentSessionForm', { studentId: student ? student.id : undefined, teacherId: teacher.id, type, responseExists: exists }, {
			success: (results) => {
				log('getTeacherStudentSessionForm results', results);
				if (exists) {
					callback(null, results)
				} else {
					callback(null, results[0])
				}

			},
			error: function (error) {
				log('corona error', error);
				callback(error, undefined)
			}
		});
	}

	getUserAffiliationForm(opts, callback) {
		log('getUserAffiliationForm', opts)
		let { student, teacher, type, exists } = opts;
		teacher = teacher || Parse.User.current();

		Parse.Cloud.run('getTeacherStudentSessionForm', { studentId: student ? student.id : undefined, teacherId: teacher.id, type, responseExists: exists }, {
			success: (results) => {
				log('getTeacherStudentSessionForm results', results);

				var result;

				for (var form of results) {
					if (form.get("student").id == Parse.User.current().id) {
						result = form;
					}
				}
				callback(null, result)
			},
			error: function (error) {
				log('corona error', error);
				callback(error, undefined)
			}
		});
	}

	getTeacherStudentSessionForm(opts, callback) {
		log('getTeacherStudentSessionForm', opts)
		let { student, teacher, session, type, returnArray } = opts;
		if (!session) {
			return;
		}
		teacher = teacher || Parse.User.current();

		Parse.Cloud.run('getTeacherStudentSessionForm', { studentId: student ? student.id : undefined, teacherId: teacher.id, sessionId: session.id, type }, {
			success: (results) => {
				log('getTeacherStudentSessionForm results', results);
				if (student && !returnArray) {
					callback(null, results[0])
				} else {
					callback(null, results)
				}
			},
			error: function (error) {
				log('corona error', error);
				callback(error, undefined)
			}
		});
	}

	transformFollowResults(results) {
		return results.map((follow) => follow.get('from'));
	}

	getUserFollowers(user, callback) {
		log('getUserFollowers')
		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', user || Parse.User.current());
		query.include('from');
		query.include('from.email');
		query.include('from.username');
		query.include('to');
		query.limit(2000);
		query.find({
			success: (results) => {
				log('getUserFollowers', results)
				callback(null, this.transformFollowResults(results))
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getUserSingleFollower(teacher, student, callback) {
		log('getUserSingleFollower', student)
		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', teacher || Parse.User.current());
		query.equalTo("from", student);
		query.include('from');
		query.include('to');
		query.limit(1);
		query.find({
			success: (follower) => {
				log('getUserSingleFollower', follower)
				callback(null, follower)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getThisUserUserFollower(student, callback) {
		log('getThisUserUserFollower', student)
		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', Parse.User.current());
		query.equalTo("from", student);
		query.include('from');
		query.include('to');
		query.limit(1);
		query.find({
			success: (follower) => {
				log('getThisUserUserFollower', follower)
				callback(null, follower.get('from'))
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getUserSessions(user, params, callback) {
		if (user)
			log('getUserSessions user.id ' + user.id);

		log('getUserSessions teacherId ' + params.teacherId);
		log('getUserSessions from ' + params.fromDate);
		log('getUserSessions to ' + params.toDate);
		log('getUserSessions unrefunded ' + params.unrefunded);
		var Session = Parse.Object.extend('MSession');

		var sessionCreator = new Parse.User({
			id: params.teacherId
		});

		var query = new Parse.Query(Session);
		query.equalTo('session_creator', sessionCreator);
		if (params.unrefunded) {
			query.equalTo('unrefunded_attenders', user);
		} else {
			if (user)
				query.equalTo('attenders', user);
		}


		if (params.fromDate && params.toDate) {
			query.greaterThanOrEqualTo('date', params.fromDate);
			query.lessThan('date', params.toDate);
		} else if (params.fromDate) {
			query.greaterThanOrEqualTo('date', params.fromDate);
		}

		query.descending('date');
		query.limit(!user ? 1000 : 500);
		// query.notEqualTo('canceled', true);
		query.find({
			success: (results) => {
				if (user) {
					log("Sessions user attended by this creator - " + results);
					callback(null, results);
				} else {
					log("Sessions by this creator - " + results);
					callback(null, results);
				}
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getTeacherSessionsTaughtInStudio(user, params, callback) {
		if (user)
			log('getTeacherSessionsTaughtInStudio user.id ' + user.id);

		log('getTeacherSessionsTaughtInStudio teacherId ' + params.teacherId);
		log('getTeacherSessionsTaughtInStudio from ' + params.fromDate);
		log('getTeacherSessionsTaughtInStudio to ' + params.toDate);
		var Session = Parse.Object.extend('MSession');

		var sessionCreator = new Parse.User({
			id: params.teacherId
		});

		var sessionTeacher;
		if (user) {
			var sessionTeacher = new Parse.User({
				id: user.id
			});
		} else {
			sessionTeacher = sessionCreator
		}

		var query = new Parse.Query(Session);

		query.notEqualTo('canceled', true);

		if (!params.insightSessions) {
			query.equalTo('session_creator', sessionCreator);
			query.equalTo('session_teacher', sessionTeacher);

			log("getTeacherSessionsTaughtInStudio fromDate", params.fromDate);
			if (params.fromDate && params.toDate) {
				query.greaterThanOrEqualTo('date', params.fromDate);
				query.lessThan('date', params.toDate);
			} else if (params.fromDate) {
				query.greaterThanOrEqualTo('date', params.fromDate);
			}
			query.limit(500);
		} else if (params.insightSessions && params.insightSessions.length > 0) {
			log("getTeacherSessionsTaughtInStudio insightSessions", params.insightSessions);
			query.containedIn('objectId', params.insightSessions);
			query.limit(1000);
		}

		query.descending('date');
		query.find({
			success: (results) => {
				log("Sessions user attended by this creator - " + results);
				log("Sessions user attended by this creator - " + results.length);
				callback(null, results);
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getFilterUserFollowers(filteredStudents, callback) {
		log('getUserFollowers')
		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', Parse.User.current());
		query.containedIn('from', filteredStudents)
		query.include('to', 'from');
		query.limit(filteredStudents.length);
		query.find({
			success: (results) => {
				// log(results)
				callback(null, this.transformFollowResults(results))
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	tryExactEmailUsersTextSearch(text, callback) {
		log('tryExactEmailUsersTextSearch');
		log('text to search ', text);
		this.getUserNotesTextSearch(text, callback);
	}

	tryExactEmailUsersTextSearchExternal(text, studioId, callback) {
		log('tryExactEmailUsersTextSearchExternal');
		log('text to search ', text);
		Parse.Cloud.run('getUserNotesTextSearch', {
			text: text,
			studioId
		}, {
			success: (results) => {
				log("getUserNotesTextSearch results", results);
				callback(null, results);
			},
			error: (error) => {
				log("getUserNotesTextSearch error", error);
				callback(error, null);
			}
		});
		
	}

	getUserNotesTextSearch(text, callback, params) {
		log('getUserNotesTextSearch', params);
		log('text to search ' + text);

		var originalPhrase = text;
		var splitPhrase = text.split(' ');
		text = splitPhrase[0];
		var text2 = splitPhrase.length > 1 ? splitPhrase[1] : null;

		var Note = Parse.Object.extend('Note');
		var noteQuery1 = new Parse.Query(Note);
		var noteQuery2 = new Parse.Query(Note);
		var noteQuery3 = new Parse.Query(Note);
		var noteQuery4 = new Parse.Query(Note);
		var noteQuery5 = new Parse.Query(Note);
		var noteQuery6 = new Parse.Query(Note);
		var noteQuery7 = new Parse.Query(Note);
		var noteQuery8 = new Parse.Query(Note);


		noteQuery1.startsWith('first_name', text);
		noteQuery2.startsWith('first_name', this.capitalizeFirstLetter(text));

		noteQuery3.startsWith('last_name', text);
		noteQuery4.startsWith('last_name', this.capitalizeFirstLetter(text));

		noteQuery5.startsWith('email', text);
		noteQuery6.startsWith('email', this.capitalizeFirstLetter(text));

		//log('search text', text + (text2 ? (' ' + text2) : ''));
		noteQuery8.startsWith('note', originalPhrase);

		var orNoteQueries = [noteQuery1, noteQuery2, noteQuery3, noteQuery4, noteQuery5, noteQuery6, noteQuery8];

		//If the search phrase is two words I assume it's a name and last name only
		if (text2) {
			noteQuery1.startsWith('last_name', text2);
			noteQuery2.startsWith('last_name', this.capitalizeFirstLetter(text2));
			orNoteQueries = [noteQuery1, noteQuery2, noteQuery8];
		} else if (!isNaN(Number(text))) {
			log('text', text);
			noteQuery7.endsWith('phone_number', text);
			orNoteQueries = [noteQuery7];
		}

		var superNoteQuery = new Parse.Query.or(...orNoteQueries);

		superNoteQuery.equalTo('teacher', Parse.User.current());
		superNoteQuery.doesNotExist('session');

		if (params && params.returnNotes) {

			//Filter students that are not followers
			var Follow = Parse.Object.extend('Follow');
			var followersQuery = new Parse.Query(Follow);
			followersQuery.equalTo('to', Parse.User.current());

			if (params && params.isArchivedStudentsList) {
				log('ArchivedStudentsList doesNotMatchKeyInQuery', params.isArchivedStudentsList);
				superNoteQuery.doesNotMatchKeyInQuery('student', 'from', followersQuery);
			} else {
				superNoteQuery.matchesKeyInQuery('student', 'from', followersQuery);
			}


			superNoteQuery.include('student');
			superNoteQuery.limit(7);
			if (params && params.limit) {
				superNoteQuery.limit(params.limit);
			}

			superNoteQuery.find({
				success: (studentNotes) => {
					log("superNoteQuery results length - ", studentNotes.length);
					studentNotes = this.removeNoteWithPrivateACLStudent(studentNotes);
					log('superNoteQuery after removeNoteWithPrivateACLStudent', studentNotes.length);
					callback(null, studentNotes);
				},
				error: function (error) {
					callback(error, null);
				}
			});

		} else {
			var Follow = Parse.Object.extend('Follow');
			var followersQuery = new Parse.Query(Follow);
			followersQuery.equalTo('to', Parse.User.current());
			followersQuery.include('from');
			followersQuery.limit(7);
			if (params && params.limit) {
				followersQuery.limit(params.limit);
			}
			followersQuery.matchesKeyInQuery('from', 'student', superNoteQuery);

			followersQuery.find({
				success: (results) => {
					log("Second results - ", results);
					;
					callback(null, this.transformFollowResults(results));
				},
				error: function (error) {
					callback(error, null);
				}
			});
		}
	}

	// ... existing code ...
	removeNoteWithPrivateACLStudent(notes) {
		if (!notes || !Array.isArray(notes)) {
			return [];
		}

		const currentUserId = Parse.User.current().id;
		if (!currentUserId) {
			return [];
		}
		log('removeNoteWithPrivateACLStudent', notes);
		const filteredNotes = notes.filter(note => {
			try {
				const studentACL = note.get('student').getACL();
				if (!studentACL) {
					log('no studentACL');
					return false;
				}

				if (studentACL.getPublicReadAccess()) {
					// log('public read access');
					return true;
				}
				// Check if the current user has read access to the student
				// log('permissionsById', studentACL.permissionsById);
				const hasReadAccess = studentACL.permissionsById[currentUserId];
				if (!hasReadAccess) {
					// log('no hasReadAccess', note.id);
				}
				return hasReadAccess;
			} catch (error) {
				log('studentACL error', error);
				return false;
			}
		});
		log('filteredNotes', filteredNotes);
		return filteredNotes;
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	addAffiliate(from, to) {
		log('addAffiliate');
		log("from", from);
		log("to", to);

		var Affiliate = Parse.Object.extend('Affiliate');
		var query = new Parse.Query(Affiliate);

		query.equalTo('to', to)
		query.equalTo('from', from)

		query.find({
			success: (results) => {
				try {
					if (!results.length) {
						log("adding new affiliate")
						var affiliate = new Affiliate();

						affiliate.save({
							to: to,
							from: from
						}, {
							success: function (res) {
								log('affiliate create success', res)
							},
							error: function (error) {
								log('Failed to create object, with error code: ' + error.message);
							}
						});
					} else {
						log('affiliate exists')
					}
				} catch (error) {
					log(error)
				}
			},
			error: function (error) {
				log(error)
			}
		});
	}

	addFollower(user, callback) {
		log('addFollower');
		log(user);

		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', Parse.User.current())
		query.equalTo('from', user)

		query.find({
			success: (results) => {
				try {
					if (!results.length) {
						log("adding new follower")
						var follow = new Follow();

						follow.save({
							to: Parse.User.current(),
							from: user
						}, {
							success: function (res) {
								log('follow create success', res)
								if (callback)
									callback(null, user);
							},
							error: function (error) {
								log('Failed to create object, with error code: ' + error.message);
								if (callback)
									callback(error);
							}
						});
					} else {
						log('follow exists')
						if (callback)
							callback(null, user);
					}
				} catch (error) {
					log(error)
				}
			},
			error: function (error) {
				if (callback)
					callback(error, null)
			}
		});
	}

	removeFollower(user, callback) {
		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', Parse.User.current())
		query.equalTo('from', user)

		query.find({
			success: (results) => {
				log(results)
				if (!results.length) {
					return;
				}
				results[0].destroy({});
				if (callback)
					callback(null, user);
			},
			error: function (error) {
				if (callback)
					callback(error, null)
			}
		});
	}

	addFollowers(users, callback) {
		log('addFollowers');
		log(users);

		log('add approved students if needed');
		var studentIds = [];
		for (var i = 0; i < users.length; i++) {
			studentIds.push(users[i].id)
		}
		this.removeStudentsFromRequestedUserRelationAndApprove({
			teacherId: Parse.User.current().id,
			students: studentIds
		}, null)

		var Follow = Parse.Object.extend('Follow');
		var query = new Parse.Query(Follow);

		query.equalTo('to', Parse.User.current())

		query.find({
			success: (results) => {
				try {
					var newFollowers = [];

					if (results.length) {
						for (var i = 0; i < users.length; i++) {
							if (!results.containsByInnerObjectId("from", users[i])) {
								var follow = new Follow();
								follow.set("to", Parse.User.current())
								follow.set("from", users[i])
								newFollowers.push(follow)
							}
						}
					} else {
						for (var i = 0; i < users.length; i++) {
							var follow = new Follow();
							follow.set("to", Parse.User.current())
							follow.set("from", users[i])
							newFollowers.push(follow)
						}
					}

					Parse.Object.saveAll(newFollowers, {
						success: function (res) {
							log('newFollowers create success', res)
							if (callback)
								callback(null, res);
						},
						error: function (error) {
							log('Failed to create objects, with error code: ' + error.message);
							if (callback)
								callback(error);
						},
					});
				} catch (error) {
					log(error)
				}
			},
			error: function (error) {
				if (callback)
					callback(error, null)
			}
		});
	}

	addedLead(user, callback) {
		log('addedLead');
		log(user);
		callback(null, user);
	}

	//REPORTS SECTION

	getStudioActivity(fromDate, toDate, callback) {
		log('fromDate', fromDate);
		log('toDate', toDate);
		Parse.Cloud.run('getStudioActivity', { fromDate, toDate, userObjectId: Parse.User.current().id }, {
			success: function (result) {
				log('result ' + result);
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error, null);
			}
		});
	}

	getPaymentsActivity(fromDate, toDate, callback) {
		Parse.Cloud.run('getPaymentsActivity', { fromDate, toDate, userObjectId: Parse.User.current().id }, {
			success: function (result) {
				log('result ' + result);
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error, null);
			}
		});
	}

	getStudentsActivity(fromDate, toDate, callback) {
		Parse.Cloud.run('getStudentsActivity', { fromDate, toDate, userObjectId: Parse.User.current().id }, {
			success: function (result) {
				log('result ' + result);
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error, null);
			}
		});
	}

	getDormantStudents(students, weeksPeriod, callback) {
		log('weeksPeriod', weeksPeriod);
		Parse.Cloud.run('checkForDormantStudents', { userIds: students, weeksPeriod, userObjectId: Parse.User.current().id }, {
			success: function (result) {
				log('result ' + result);
				callback(null, result);
			},
			error: function (error) {
				log('error', error);
				callback(error, null);
			}
		});
	}

	//Selected sort and Filter options array of user
	//action=sort/filter
	//table=student/sesseion/plan

	//sort options are at sortOptionToIndex
	//filter options are at filterOptionToIndex
	getSortOrFilterSelections(user, action, table) {
		log('getSortOrFilterSelections user', user);
		log('getSortOrFilterSelections action', action);
		log('getSortOrFilterSelections table', table);

		try {
			if (!user)
				return [];
			if (!user.get('preferences'))
				return [];

			if (!action)
				action = "sort";

			let sortOrFilterData = user.get('preferences').get([action]);//one of the sortTableTypes filterTableTypes

			// sort : { 
			// 	sortTableTypes
			// }

			// filter : { 
			// 	filterTableTypes
			// }

			//If specified table of specific option (student_sort)
			if (table && sortOrFilterData) {
				sortOrFilterData = sortOrFilterData[table]
			}

			log('getSortOrFilterSelections sortOrFilterData', sortOrFilterData);
			return sortOrFilterData;
		} catch (error) {
			return [];
		}
	}

	//Selected sort and Filter options array of user
	//action=sort/filter
	//table=student/sesseion/plan

	//sort options are at sortOptionToIndex
	//filter options are at filterOptionToIndex
	saveSortOrFilterSelections(user, action, data, table) {
		log('saveSortOrFilterSelections user', user);
		log('saveSortOrFilterSelections action', action);
		log('saveSortOrFilterSelections data', data);
		log('saveSortOrFilterSelections table', table);

		try {
			if (!user)
				return;
			if (!user.get('preferences'))
				return;

			if (!action)
				action = "sort";

			//Specific sort or filter table option to save (student_sort)
			if (table) {
				data = {
					[table]: data
				}//one of the sortTableTypes filterTableTypes
			}
			log('saveSortOrFilterSelections action', action);

			this.saveUserPreferences({ [action]: data })
			return sortOrFilter;
		} catch (error) {
			return [];
		}
	}

	handleApplePayRegistration(callback) {
		log('handleApplePayRegistration');
		try {
			if (config.ENV === 'development') {
				console.log("process.env.PAYME_KEY", config.PAYME_KEY);
				console.log("process.env.VAS_KEY", config.VAS_KEY);
				console.log("process.env.APPLE_MERCHENT_ID", config.APPLE_MERCHENT_ID);
				console.log("Parse.User.current().get('payme_seller_id')", Parse.User.current().get('payme_seller_id'));
			}
			let headers = {
				'Content-Type': 'application/json'
			}
			let axiosConfig = {
				'headers': headers
			};

			var locale = this.getPreferences().language || "en";
			locale = locale.slice(0, 2);

			let params = {
				"payme_client_key": config.PAYME_KEY,
				"seller_payme_id": Parse.User.current().get('payme_seller_id'),
				"vas_payme_id": config.VAS_KEY,
				vas_data: {
					websites:
						[
							config.VAS_APP_URL.replace(/^https?:\/\//, ''),//'medidate-admin-sandbox.herokuapp.com',
							'medidate-admin.herokuapp.com',
							'admin.medidatewith.me'
						]
				},
				"language": locale
			}

			log("axios post url: " + config.VAS_REQUEST_URL)
			log("axios post params: " + params)
			log("axios post vas_data: " + params.vas_data)

			axios.post(config.VAS_REQUEST_URL, params, axiosConfig)
				.then(response => {
					log("response VAS_REQUEST_URL: ");
					log(response)
					callback(null)
				})
				.catch(error => {
					log(error);
					callback(error);
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						log(error.response.data);
						log(error.response.status);
						log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						log('Error', error.message);
					}
				});


			// let headers = {
			// 	'Content-Type': 'application/json'
			// }

			// const options = {
			// 	method: 'POST',
			// 	url: config.VAS_REQUEST_URL,
			// 	headers: headers,
			// 	data: {
			// 		payme_client_key: config.PAYME_KEY,
			// 		seller_payme_id: Parse.User.current().get('payme_seller_id'),
			// 		vas_payme_id: config.VAS_KEY,
			// 		vas_data: { 
			// 			websites: 
			// 			[
			// 				'admin.medidatewith.me',
			// 				'medidate-admin.herokuapp.com',
			// 				'medidate-admin-sandbox.herokuapp.com',
			// 				'medidate-admin.herokuapp.com/#',
			// 				'medidate-admin-sandbox.herokuapp.com/#',
			// 				'medidate-admin.herokuapp.com/#/dashboard',
			// 				'medidate-admin-sandbox.herokuapp.com/#/dashboard'
			// 			] 
			// 		},
			// 		language: this.getPreferences().language || 'en'
			// 	}
			// };

			// console.log("options", options);

			// axios.request(options).then(function (response) {
			// 	console.log(response.data);
			// }).catch(function (error) {
			// 	console.error(error);
			// });
		} catch (e) {
			log(e);
		}

	}

	//REPORTS SECTION
	getCurrencyByBankCountry(user) {
		try {
			var bankCountry = user.get('bank_country');
			var country = user.get('country');
			if (bankCountry === 'Israel' || country === 'Israel') {
				return 'ILS';
			} else if (bankCountry === 'United States' || country === 'United States') {
				return 'USD';
			} else if (Payment.isBankCountryEuropeanCountry(user)) {
				return 'EUR';
			} else if (user.get('preferences') && user.get('preferences').get("currency")) {
				return user.get('preferences').get("currency");
			} else {
				return 'USD';
			}
		} catch (error) {
			return 'USD';
		}
	}
}

export default new User();
